<template>
  <SignComponent
    title="Welcome to WindESCo"
    subTitle="Please enter your credentials to proceed."
  >
    <div class="d-flex flex-column align-center card-body login">
      <v-form @submit.prevent="handleSubmit" v-model="valid">
        <v-text-field
          persistent-placeholder
          outlined
          dense
          v-model="loginForm.email"
          label="Email"
          type="username"
          autocomplete="on"
          placeholder="example@gmail.com"
          :rules="emailRules"
          data-cy="email"
        />
        <v-text-field
          persistent-placeholder
          outlined
          dense
          v-model="loginForm.password"
          type="password"
          label="Password"
          autocomplete="current-password"
          :rules="passwordRules"
          data-cy="password"
        />
        <div class="mt-8 d-flex flex-column align-center">
          <v-btn
            @click.prevent="handleSubmit"
            color="green"
            class="px-4 mb-4 white--text"
            type="submit"
            :loading="loading"
            data-cy="submit"
          >
            Sign in
          </v-btn>
          <v-btn text class="p-0" to="/forgotpassword">Forgot password</v-btn>
        </div>
      </v-form>
    </div>
    <div class="d-flex justify-center" v-if="isChina">
      {{ icpNotice }}
    </div>
  </SignComponent>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SignComponent from "@/components/SignComponent";
import { isChina, icpNotice } from "@/helpers/variables";

export default {
  name: "Login",
  components: {
    SignComponent,
  },
  data() {
    return {
      loginForm: {
        email: null,
        password: null,
      },
      submitted: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+/.test(v) || "Email is invalid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      valid: false,
      isChina,
      icpNotice,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.user.loading.login,
      errors: (state) => state.error.errors,
    }),
  },
  methods: {
    ...mapActions({
      login: "user/login",
    }),
    async handleSubmit() {
      const loginRes = await this.login(this.loginForm);
      this.submitted = true;
      if (loginRes) {
        await this.$router.push({ path: "/" }).catch((err) => {});
      }
    },
  },
};
</script>

<style lang="scss">
.login form {
  width: 75%;
  max-width: 350px;
}
label.theme--dark + input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important; /* inherit only works in Safari */
  -webkit-text-size-adjust: inherit !important;
}

label.theme--light + input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important; /* inherit only works in Safari */
  -webkit-text-size-adjust: inherit !important;
}

/* When dark theme, then make keychain icon white */
label.theme--dark + input::-webkit-credentials-auto-fill-button {
  background-color: #fff !important;
}

/* Hide credentials-auto-fill-button in password inputs, only visible on other inputs */
input[type="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
}
</style>
